/**
 * SiteMeta component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Helmet, { HelmetProps } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"

type SiteMetaProps = Pick<HelmetProps, "meta"> & {
  description?: string
  lang?: string
  title?: string
  cardImage?: GatsbyTypes.GatsbyImageSharpFixedFragment
}

const SiteMeta: React.FC<SiteMetaProps> = ({
  description = ``,
  lang = `en`,
  meta = [],
  title,
  cardImage,
}) => {
  const { pathname } = useLocation()

  const { site, image } = useStaticQuery<GatsbyTypes.SiteMetaQuery>(
    graphql`
      query SiteMeta {
        site {
          siteMetadata {
            title
            description
            author
            creator
            siteUrl
            fbAppId
          }
        }
        image: file(relativePath: { eq: "og-image-default.png" }) {
          childImageSharp {
            fixed(quality: 60, width: 1200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )

  const metaDescription = description || site?.siteMetadata?.description || ``
  const metaImage = cardImage || image?.childImageSharp?.fixed
  const imageSrc =
    metaImage?.src && `${site?.siteMetadata?.siteUrl || ""}${metaImage.src}`
  const url = `${site?.siteMetadata?.siteUrl || ""}${pathname}`

  const pageTitle = title
    ? `${title} | ${site?.siteMetadata?.title}`
    : site?.siteMetadata?.title

  const creator = site?.siteMetadata?.creator

  return (
    <Helmet htmlAttributes={{ lang }} title={pageTitle}>
      <meta name="title" content={pageTitle} />
      <meta name="description" content={metaDescription} />
      <meta name="author" content={site?.siteMetadata?.author} />

      <meta property="fb:app_id" content={site?.siteMetadata?.fbAppId} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={metaDescription} />
      {metaImage && <meta property="og:image" content={imageSrc} />}
      {metaImage && (
        <meta property="og:image:width" content={`${metaImage.width}`} />
      )}
      {metaImage && (
        <meta property="og:image:height" content={`${metaImage.height}`} />
      )}

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={pageTitle} />
      {creator && <meta property="twitter:creator" content={creator} />}
      <meta property="twitter:description" content={metaDescription} />
      {metaImage && <meta property="twitter:image" content={imageSrc} />}
    </Helmet>
  )
}

export default SiteMeta
