import React from "react"
import classNames from "classnames"
import styles from "./Page.module.scss"

export enum PageHeight {
  Full,
  Half,
  TwoThirds,
  Third,
}

const heightStyles = [styles.full, styles.half, styles.twoThirds, styles.third]

interface PageProps extends React.HTMLAttributes<HTMLDivElement> {
  height?: PageHeight
}

const Page = React.forwardRef<HTMLDivElement, PageProps>(
  ({ height = PageHeight.Full, className, ...rest }, ref) => (
    <div
      className={classNames(styles.container, heightStyles[height], className)}
      {...rest}
      ref={ref}
    />
  )
)

Page.displayName = "Page"

export default Page
